import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import queryString from 'query-string'

import {Layout} from '../components/index'
import markdownify from '../utils/markdownify'

export default class RSVP extends React.Component {
  state = {
    inviteCode: '',
    validInviteCode: false,
    error: '',
    submitted: false,
    invitation: {}
  }
  inviteCode = null

  componentDidMount () {
    const query = queryString.parse(window.location.search)

    if (query.inviteCode) {
      this.inviteCode = query.inviteCode
      this.fetchInvitation()
    }
  }

  render () {
    return (
      <Layout {...this.props}>
        <section id="main" className="wrapper style1">
          <div className="inner">
            <header className="major">
              <h1>{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
              {markdownify(_.get(this.props, 'pageContext.frontmatter.subtitle'))}
            </header>

            <div>
              {this.inviteCodeForm()}
              {this.rsvpForm()}
              {this.thankYou()}
            </div>
          </div>
        </section>
      </Layout>
    )
  }

  requestOptions = () => {
    return {
      baseURL: process.env.API_URL,
      timeout: 65000,
      headers: {
        'invite-code': this.inviteCode || this.state.inviteCode
      }
    }
  }

  inviteCodeChange = (event) => {
    this.setState({ ...this.state, inviteCode: event.target.value })
  }

  fetchInvitation = () => {
    axios.get('/invitations',
     this.requestOptions()
    )
    .then((response) => {
      this.setState({
        error: '',
        validInviteCode: true,
        invitation: response.data
      })
    })
    .catch((error) => {
      switch (error.response.status) {
        case 401:
          this.setState({
            error: "That doesn't appear to be a valid Invite Code, please try again.",
            validInviteCode: false
          })
          break;
      
        default:
          this.setState({
            error: "It looks like the server is having an issue, please try again later.",
            validInviteCode: false
          })
          break;
      }
    })
  }

  renderError = () => {
    if (this.state.error.length > 0) {
      return (
        <p className="error">{this.state.error}</p>
      )
    }
  }

  inviteCodeForm = () => {
    if (this.state.submitted || this.state.validInviteCode) return;

    return (
      <form id="inviteCodeForm">
        <div className="fields">
          <div className="field">
            <input type="text" name="inviteCode" value={this.state.inviteCode} placeholder="Invite Code" onChange={this.inviteCodeChange} />
            {this.renderError()}
          </div>
          <div className="field">
            <button type="button" className="pull-right" onClick={this.fetchInvitation}>RSVP Now</button>
          </div>
        </div>
      </form>
    )
  }

  invitationFieldChange = (event) => {
    this.setState({
      ...this.state,
      invitation: {
        ...this.state.invitation,
        [event.target.name]: event.target.value
      }
    })
  }

  invitationCheckChange = (event) => {
    this.setState({
      ...this.state,
      invitation: {
        ...this.state.invitation,
        [event.target.name]: event.target.checked
      }
    })
  }

  inviteeStatusChange = (event) => {
    const data = event.target.name.split('-')
    const inviteeId = parseInt(data[0])
    const fieldName = data[1]

    const invitees = this.state.invitation.invitees.map((invitee) => {
      if (invitee.id === inviteeId) {
        invitee[fieldName] = event.target.checked
        return invitee
      }
      return invitee
    })

    this.setState({
      ...this.state,
      invitation: {
        ...this.state.invitation,
        invitees
      }
    })
  }

  renderInvitees = () => {
    const { invitees } = this.state.invitation

    return invitees.map((invitee) => {
      const fieldName = `${invitee.id}-will_attend`

      return (
        <div key={invitee.id}>
          <input
            type="checkbox"
            name={fieldName}
            id={fieldName}
            checked={invitee.will_attend}
            onChange={this.inviteeStatusChange} />
          <label htmlFor={fieldName}>{invitee.first_name} {invitee.last_name}</label>
        </div>
      )
    })
  }

  renderPlusOne = () => {
    const { invitation } = this.state
    if (!invitation.plus_one_allowed) return;

    return (
      <div>
        <input
          type="checkbox"
          name="plus_one_attending"
          id="plus_one_attending"
          checked={invitation.plus_one_attending}
          onChange={this.invitationCheckChange} />
        <label htmlFor="plus_one_attending">I'll be bringing a +1</label>
      </div>
    )
  }

  renderInviteeOptions = () => {
    return (
      <div className="field">
        <label htmlFor="invitees">Check each person who will attend (if no one will attend, please submit the form anyway):</label>
        <fieldset name="invitees">
          {this.renderInvitees()}
          {this.renderPlusOne()}
        </fieldset>
      </div>
    )
  }

  submitRSVP = () => {
    const invitation = JSON.parse(JSON.stringify(this.state.invitation))
    const invitees = JSON.parse(JSON.stringify(this.state.invitation.invitees))
    const invitationId = invitation.id

    delete invitation.id
    delete invitation.invitees
    delete invitation.plus_one_allowed
    delete invitation.has_responded

    invitees.forEach((invitee) => {
      delete invitee.first_name
      delete invitee.last_name
    })

    const postData = {
      invitation: {
        ...invitation,
        invitees_attributes: [
          ...invitees
        ]
      }
    }

    axios.put(`/invitations/${invitationId}`,
      postData,
      this.requestOptions()
    )
    .then(() => {
      this.setState({
        ...this.state,
        submitted: true
      })
    })
  }

  rsvpForm = () => {
    if (this.state.submitted || !this.state.validInviteCode) return;

    const { invitation } = this.state

    return (
      <form id="rsvpForm">
        <div className="fields">
          {this.renderInviteeOptions()}
          <div className="field">
            <label htmlFor="food_allergies">Dietary Restrictions or Food Allergies:</label>
            <input
              type="text"
              name="food_allergies"
              value={invitation.food_allergies}
              onChange={this.invitationFieldChange} />
          </div>
          <div className="field">
            <label htmlFor="comments">Comments, song requests, or anything else we should know:</label>
            <textarea
              name="comments"
              value={invitation.comments}
              onChange={this.invitationFieldChange} />
          </div>
          <div className="field">
            <button type="button" className="pull-right" onClick={this.submitRSVP}>Submit</button>
          </div>
        </div>
      </form>
    )
  }

  thankYou = () => {
    if (!this.state.submitted) return false;

    return (
      <header className="major">
        <p>Thanks for responding! If you need to update your RSVP at any point feel free come back and edit it.</p>
      </header>
    )
  }
}
